import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Seo from "../components/Seo"
import Layout from "../components/layout/Layout"
import GetUpdates from "../components/GetUpdates"

const BasicPageTemplate = ({ data }) => {
  const {
    title,
    featuredImage,
    body,
  } = data.contentfulBasicPage
  const hero = getImage(featuredImage)

  return (
    <>
      <Seo title={title} bodyClass="layout__basic-page presentation" />
      <Layout>
        <section id="content" className="layout__main s__white basic-page">
          <div className="basic-page__content">
            {featuredImage &&
              <div className="hero__wrapper">
                <GatsbyImage
                  image={hero}
                  alt=""
                  className="hero__img"
                />
              </div>
            }
            <header className="basic-page__content__header u__spacing">
              <div className="row u__container">
                <div className="col-sm-7 basic-page__content__title-wrapper">
                  <h1 className="basic-page__content__title display-5 pt-4 mb-2">{title}</h1>
                </div>
              </div>
            </header>
            <article className="container u__container u__vspacing basic-page__content__body">
              <div className="row">
                <div className="col-sm-9 col-lg-8" dangerouslySetInnerHTML={{__html: body.childMarkdownRemark.html}}></div>
              </div>
            </article>
          </div>
        </section>
        <GetUpdates />
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query BasicPageQuery($slug: String!) {
    contentfulBasicPage(slug: { eq: $slug }) {
      title
      slug
      featuredImage {
        ... on ContentfulAsset {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`

export default BasicPageTemplate
